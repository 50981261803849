.App {
  text-align: center;
  /*background-color: #e0f7fa; /* Calm blue background */
  height: auto; /* Full height */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/*
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input-field,
.output-field {
  width: 60%; /* Adjust width as needed */
  margin: 10px;
  padding: 10px;
  border-radius: 15px; /* Round corners */
  border: 1px solid #ccc; /* Light grey border */
  font-size: 1em;
  resize: none; /* Prevent resizing for textareas */
}

.input-field {
  height: 100px; /* Adjust height as needed */
}

.output-field {
  height: 100px; /* Adjust height as needed */
  background-color: #f0f4c3; /* Light green background for output */
}

.evaluation-field {
  width: 60%; /* Adjust width as needed */
  margin: 10px;
  padding: 10px;
  border-radius: 15px; /* Round corners */
  border: 1px solid #ccc; /* Light grey border */
  font-size: 1em;
  resize: none; /* Prevent resizing for textareas */
  height: 150px; /* Adjust height as needed */
  background-color: #f0f4c3;
}

.sanakirja-field {
  width: 36vw; /* Adjust height as needed */
  background-color: #f1d1aa; /* Light green background for output */
}

.word-checking {
  width: 150px; /* Adjust height as needed */
  height: 30px;
  font-size: 16px;
  background-color: #ffffff; /* Light green background for output */
}

.analytics-field {
  box-sizing: border-box;
  width: 20vw; /* Adjust width as needed */
  margin: 0px;
  margin-top: 30px;
  margin-bottom: 50px;
  border-radius: 15px; /* Round corners */
  border: 1px solid #7cb5c5; /* Light grey border */
  font-size: 1.3em;
  resize: none; /* Prevent resizing for textareas */
  background-color: #7cb5c5;
  height: 20vh; /* Adjust height as needed */
  vertical-align: top;
  padding: 10px 15px 10px 15px;
}

.analytics-panel {
  box-sizing: border-box;
  width: 100%; /* Adjust width as needed */
  margin: 0px;
  margin-top: 0px;
  padding: 0px;
  font-size: 1.1em;
  height: 2vh; /* Adjust height as needed */
}

.centered {
  text-align: center;
  width: 100%; /* Adjust width as needed */
  margin: 0px;
  margin-top: 30px;
  padding: 0px;
  font-size: 1.1em;
  height: 2vh; /* Adjust height as needed */
}

.basic-form {
  width: 160px; /* Adjust height as needed */
  height: 30px;
  vertical-align: middle;
  font-size: 16px;
  background-color: #aaf1c4; /* Light green background for output */
}

button {
  margin: 10px;
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  background-color: #4caf50; /* Green button */
  color: white;
  font-size: 1em;
  cursor: pointer;
}

button:hover {
  background-color: #388e3c; /* Darker green on hover */
}

.center-text-field .MuiInputBase-input {
  text-align: center !important;
}

.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 1000; /* Ensure modal appears above other content */
}

/* Style the modal content */
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
